nav ul {
    margin: 0 !important;
    padding: 0 !important;
    background-color: #2e3138;
}
nav ul li {
    list-style: none;
    display: inline-block;
    padding: 10px;
}

nav a {
    text-decoration: none;
    color: white;
    font-weight: bold;
}