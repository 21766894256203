.content {
    font-size: x-large;
    color: white;
    padding: 10px 20px;
    text-align: left;

    a {
        color: cadetblue;
    }

    [src^="data:"] {
        display: none;
    }

    blockquote {
        border-left: 10px solid #ddd;
        margin-left: 0;
        padding: 10px;
        padding-left: 20px;
    }

    img {
        width: 100%;
    }
}

.Article img.banner {
    width: 100%;
}

.App-header {
    h1 {
        padding: 10px 20px;
    }

    .st {
        font-size: large;
        padding-bottom: 20px;
    }
}