.item {
	display: inline-block;
	height: 400px;
	width: 400px;
	vertical-align: top;
	overflow: hidden;
	border: 1px solid #555;

	.inner {
		display: block;
		background-position: 50% 50%;
		background-size: cover;
		//filter: grayscale(1);
		height: 100%;
		transition: all 0.25s ease-in-out;

		&:hover {
			//filter: grayscale(0);
			background-color: #282c34;
		}

		h2 {
			margin: 0;
		}


		a {
			background-color: #282c34e7;
			text-decoration: none;
			padding: 10px;
			color: #fff;
			display: block;
			transition: all 0.25s ease-in-out;
			
		}
		&:hover a {
			background-color: #282c3471;
		}
	}
}

button {
	padding: 10px;
	margin: 10px;
}
